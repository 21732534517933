<style scoped>
::v-deep(.q-field__native) {
  text-align: center;
}
::v-deep(.q-select .q-field__input) {
  text-align: center;
}
::v-deep(.q-field--standout.q-field--highlighted .q-field__native) {
  text-align: center;
}
label {
  width: 220px;
}

::v-deep(.q-field__native) {
  font-size: 14px !important;
}
</style>
<style>
.unsub-btn {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 10px;
  background: #ed885c;
  border: 1px solid #ed885c;
  padding: 10px 15px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-top: 30px;
}
.unsub-btn:hover {
  background: transparent;
  color: #ed885c;
}
</style>
<template>
  <div class="title">Введите ваш номер телефона</div>
  <q-input
    :rules="phoneValidate"
    rounded
    standout
    ref="inputPhone"
    placeholder="+7 (___) ___ __ __"
    v-model="phone"
    class=""
    mask="+7 (###) ###-####"
  >
  </q-input>
  <button class="unsub-btn" @click="unsub()">
    ОТПИСАТЬСЯ
  </button>
</template>

<script>
export default {
  name: "UnsubForm",

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  data: () => ({
    phone: "",
  }),
  methods: {
    unsub() {
      
    },
  },
  mounted() {},
};
</script>
