<style>
.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 55px;
  margin-bottom: 55px;
}
circle-progress::part(base) {
  width: 175px;
  height: auto;
  background: white;
  border-radius: 100%;
  padding: 8px;
}
circle-progress::part(value) {
  stroke: #e79e83;
  stroke-dasharray: 1%;

  stroke-width: 10px;
}
circle-progress::part(circle) {
  stroke-dasharray: 1%;
  stroke: transparent;

  stroke-width: 10px;
}
circle-progress::part(text) {
  fill: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
}

.params-wrapper {
  max-width: 600px;
  width: 100%;
  border-radius: 20px;
  background: white;
  padding: 23px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.params {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.param {
  display: flex;
  align-items: center;
}
.param-title {
  color: #000;

  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
}
.param-value {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-left: 10px;
}
.text-title {
  color: #000;

  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}
</style>
<style>
.anketa-wrap {
  max-width: 600px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 500px){
  .param-title {
    font-size: 12px;

  }
  .param-value {
    font-size: 12px;
  }
  .text-title  {
    font-size: 16px;
  }
 
}
</style>
<template>
  <div class="params-wrapper">
    <div class="params">
      <div class="amount param">
        <div class="param-title">Сумма:</div>
        <div class="param-value">{{ this.form.amount }} ₽</div>
      </div>
      <div class="period param">
        <div class="param-title">Срок:</div>
        <div class="param-value">{{ this.form.period }} дн.</div>
      </div>
      <div class="percent param">
        <div class="param-title">Ставка:</div>
        <div class="param-value">0%</div>
      </div>
    </div>
  </div>
  <circle-progress
    class="circle"
    animationDuration="5000"
    textFormat="percent"
    value="100"
    max="100"
  >
  </circle-progress>
  <div class="text-title">
    Мы проводим оценку анкеты для подбора лучших условий
  </div>
</template>

<script>
export default {
  name: "RedirectToCard",
  props: {
    forwardPage: Function,
  },
  data() {
    return {
      sendPromise: Promise.resolve(),
      form: {
        amount: 0,
        period: 0,
      },
    };
  },
  created() {
    this.form.amount = localStorage.getItem("amount");
    this.form.period = localStorage.getItem("period");
    setTimeout(() => {
      this.forwardPage(this.sendPromise);
    }, 5000);
  },
  mounted() {},
};
</script>
