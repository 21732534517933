
import { createApp } from 'vue'
import App from './App.vue'
import * as DataParams from './assets/js/data_params.js'
import Mask from '@/directives/Mask'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { createStore } from 'vuex'
import { circleCounter } from 'vue-circle-counter'




//Подключение бутстрапа

var themeColor = DataParams.get('theme', 'md-blue');

//Подключение бутстрапа
    var themeStyle = document.createElement('link');
    themeStyle.rel = 'stylesheet';
    themeStyle.href = DataParams.getOriginUrl() + `/css/themes/${themeColor}.css`;
    document.getElementsByTagName('head')[0].appendChild(themeStyle);


window.addEventListener('load', () => {

    var $consent = document.querySelector('.vue-app consent');
    if ($consent) {
        window.consent = $consent.innerHTML;
    }

   

    const store = createStore({
        state () {
            return {
                anketa: {
                    amount: 0,
                    period: 0
                }
            }
        },
        mutations: {
            updateAnketa (state, payload) {
                state.anketa = Object.assign({}, state.anketa, payload)
            }
        }
    })
  
  

    const elements = document.querySelectorAll('.vue-app');
    elements.forEach((element, index) => {
        const app = createApp(App).use(Quasar, quasarUserOptions );
        app.directive('mask', Mask);

        app.use(store);

        app.mount(element); // Монтируем Vue на каждый найденный элемент
    });
  
    
});
