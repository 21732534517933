<style>
.q-avatar {
  width: 16px !important;
  top: -6px;
}

.phone-input .q-avatar {
  width: 14px !important;
}
.q-field--standout.q-field--highlighted .q-field__control {
  background: #f5f5f5 !important;
  box-shadow: none !important;
}
.q-field__native {
  font-size: 12px !important;
}
.q-field__label {
  color: #6d6d6d !important;
  font-weight: 400 !important;
  top: 11px !important;
}
.q-field--standout.q-field--highlighted .q-field__input {
  color: #6d6d6d !important;
}
.q-select .q-field__input {
  font-size: 12px !important;
}
.q-field--auto-height .q-field__control {
  height: 40px !important;
  min-height: 40px !important;
}
.q-field--standout.q-field--rounded .q-field__control {
  height: 40px !important;
  min-height: 40px !important;
}
.material-icons {
  font-size: 0px !important;
}
.q-spinner {
  margin-top: -10px;
}
.q-field--standout.q-field--highlighted .q-field__native {
  color: #6d6d6d !important;
}
.phone-input .q-field__label {
  top: 10px !important;
}
.q-checkbox__inner--truthy .q-checkbox__bg {
  background: #ed885c !important;
}
.q-checkbox__bg {
  border: 2px solid #ed885c !important;
}
.q-field--auto-height .q-field__native {
  min-height: 30px !important;
}
.q-field--with-bottom {
  margin-bottom: 5px !important;
}
.q-field__bottom--animated {
  bottom: -10px !important;
}
.q-checkbox__bg {
  top: -50% !important;
}
@media (max-width: 768px) {
		.q-checkbox__bg{
			top: 0% !important;
		}
	}
@media (max-width: 441px) {
		.q-checkbox__bg{
			top: -30% !important;
		}
	}
@media (max-width: 400px) {
		.q-checkbox__bg{
			top: -50% !important;
		}
	}
</style>
<style scoped>
.anketa-wrap {
  margin: 0 auto;
  padding: 0;
}
@media (max-width: 450px) {
  .anketa-wrap {
    padding: 0;
  }
}
/*slider*/

.min,
.max {
  display: none;
}
.text {
  color: #040404;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.slider-ui {
  position: relative;
  width: 210px;
  height: 40px;
}
.slider-ui input {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.slider-ui .bar {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-ui .min,
.slider-ui .max {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 800;
  color: #fff;
}
.slider-ui .min {
  left: 2%;
}
.slider-ui .max {
  right: 2%;
}
.slider-ui .track {
  position: absolute;
  z-index: 3;
  left: 25px;
  right: 25px;
  top: 0;
  bottom: 0;
}
.slider-ui .value {
  position: absolute;
  left: 50%;
  top: 18%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  background-color: #ed885c;
  border: 4px solid #000;
  border-radius: 100%;
  box-sizing: border-box;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out, color 0.3s ease-in-out;
}
.slider-ui .value.up {
  top: -110%;
  color: #000;
}
.slider-ui.color1 .bar {
  background-color: #f5f5f5;
}
.slider-ui.color2 .bar {
  background-color: #f5f5f5;
}
.slider-ui.color1 .value {
  border-color: #ed885c;
  transition: all 0.3s ease-in-out;
}
.slider-ui.color2 .value {
  border-color: #ed885c;
  transition: all 0.3s ease-in-out;
}
.sum-wrapper,
.days-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}
.slider-display {
  background: #f5f5f5;
  border-radius: 56px;
  width: 80px;
  padding: 9px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider-value {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
}
.slider-value-2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  padding-left: 15px;
}
.docs-wrapper {
  margin-top: 18px !important;
}
.currency {
  color: #767676;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.info-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .info-wrapper {
    justify-content: normal;
  }
 .info-text{
  margin-left: 12px;
 }
}
.info-wrapper img {
  margin-top: 5px;
}
.consent-text {
  width: 90%;
}
.form-button {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 10px;
  background: #ed885c;
  border: 1px solid #ed885c;
  padding: 10px 15px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.form-button:hover {
  background: transparent;
  color: #ed885c;
}
.anketa-wrap {
  max-width: 300px;
  margin: 0 auto;
  padding: 0 !important;
}

</style>

<template>
  <div class="calculator-wrapper anketa-wrap">
    <div class="sum-wrapper">
      <div class="slider-ui color1">
        <input type="range" min="5000" max="60000" step="5000" />
        <div class="bar">
          <span class="min"></span>
          <span class="text">Сумма</span>
          <span class="max"></span>
        </div>
        <div class="track">
          <div class="value"></div>
        </div>
      </div>
      <div class="slider-display">
        <span class="slider-value">{{ form.amount }}</span>
        <span class="currency">₽</span>
      </div>
    </div>

    <!-- Второй слайдер для подсчета дней -->
    <div class="days-wrapper">
      <div class="slider-ui color2">
        <input type="range" min="5" max="60" step="5" />
        <div class="bar">
          <span class="min"></span>
          <span class="text">Срок</span>
          <span class="max"></span>
        </div>
        <div class="track">
          <div class="value"></div>
        </div>
      </div>
      <div class="slider-display">
        <span class="slider-value slider-value-2">{{ form.period }}</span>
        <span class="currency">дн</span>
      </div>
    </div>

    <div class="form-wrapper">
      <q-select
        :color="themeColor"
        :rules="fioValidate"
        v-model="form.fio"
        use-input
        rounded
        standout
        hide-selected
        @input-value="(val) => (form.fio = val)"
        fill-input
        input-debounce="0"
        placeholder="ФИО"
        class="input-req"
        :options="FioSuggestOptions"
        @filter="fetchFio"
        ref="inputFio"
        new-value-mode="add"
        behavior="menu"
      >
        <template v-slot:prepend>
          <q-avatar>
            <img src="img/fio.svg" />
          </q-avatar>
        </template>
      </q-select>
      <q-input
        :rules="phoneValidate"
        rounded
        standout
        ref="inputPhone"
        placeholder="Телефон"
        v-model="form.phone"
        class="input-req input phone-input"
        mask="+7 (###) ###-####"
      >
        <template v-slot:prepend>
          <q-avatar>
            <img src="img/phone.svg" />
          </q-avatar>
        </template>
      </q-input>
      <q-select
        :color="themeColor"
        :rules="emailValidate"
        :model-value="form.email"
        use-input
        hide-selected
        @input-value="(val) => (form.email = val)"
        fill-input
        rounded
        class="input-req"
        ref="inputEmail"
        aria-expanded="true"
        standout
        :options="emailSuggestOptions"
        @filter="fetchEmailSuggest"
        new-value-mode="add"
        placeholder="Email"
        behavior="menu"
      >
        <template v-slot:prepend>
          <q-avatar>
            <img src="img/email.svg" />
          </q-avatar>
        </template>
      </q-select>
      <div class="docs-wrapper">
        <q-checkbox
          ref="consentCheckbox"
          @click="removeClass"
          v-model="form.consent"
        />
        <div
          class="consent-text"
          ref="consentText"
          v-html="getConsentText()"
        ></div>
      </div>
      <div class="info-wrapper">
        <img src="img/rkn-ico.png" alt="" />
        <div class="consent-text info-text">
          Ваши данные надежно защищены, Являемся оператором персональных данных
          № 66-23-027543, Приказ № 179 от 08.12.2023.
        </div>
      </div>
      <button class="form-button" @click="next()">ЗАПОЛНИТЬ АНКЕТУ</button>
    </div>
  </div>
</template>

<script>
import * as Auth from "@/assets/js/auth.js";
import { debounce } from "debounce";
import vSelect from "@/../node_modules/vue-select/src/index.js";
import * as Bid from "@/assets/js/bid/default_create.js";
import * as ConfirmTel from "@/assets/js/confirm_tel.js";
import * as Suggest from "@/assets/js/suggest.js";
import * as Validate from "@/assets/js/validators.js";
import * as GetParams from "@/assets/js/get_params.js";
import * as DataParams from "@/assets/js/data_params.js";
import * as Goal from "@/assets/js/goal.js";

export default {
  name: "FormWithCalculator",

  components: { vSelect },

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  data: () => ({
    sendPromise: Promise.resolve(),
    FioSuggestOptions: [],
    error: null,
    docs: false,
    consentTextDefault:
      '<a href="docs/Согласие_на_получение_информационой_и_рекламной_рассылки.pdf" target="_blank"> рекламной и информационной рассылок</a>',
    themeColor: DataParams.get("color"),
    isNextClicked: false,
    isPartialSubmitted: false,
    sendPromise: Promise.resolve(),
    emailSuggestOptions: [],
    form: {
      fio: null,
      phone: null,
      email: null,
      consent: false,
      amount: 0,
      period: 0,
      bidId: null,
    },
    valid: {
      fio: true,
      phone: true,
      email: true,
      consent: true,
    },
  }),

  computed: {
    //validation
    phoneValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите телефон",
        (val) => Validate.phone(val) || "Введите полностью",
      ];
    },
    emailValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите email",
        (val) => Validate.email(val) || "Введите полностью",
      ];
    },
    fioValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите ФИО",
        (val) => Validate.textOnly(val) || "Введите корректно",
      ];
    },
    formatSum() {
      return this.form.amount.toLocaleString("ru-RU");
    },
  },

  watch: {},
  methods: {
    initializeSliders() {
      const sliders = document.querySelectorAll(".slider-ui");

      sliders.forEach((slider) => {
        let input = slider.querySelector("input[type=range]");
        let min = parseInt(input.getAttribute("min"));
        let max = parseInt(input.getAttribute("max"));
        let valueElem = slider.querySelector(".value");
        let sliderDisplay =
          slider.nextElementSibling.querySelector(".slider-value");

        // Определяем, к какому слайдеру привязывать значения
        let isAmountSlider = slider.classList.contains("color1"); // Первый слайдер (сумма)
        let isPeriodSlider = slider.classList.contains("color2"); // Второй слайдер (срок)

        // Устанавливаем значение по умолчанию
        let defaultValue = isAmountSlider
          ? (min + max) / 5 // Начальное значение для суммы
          : (min + max) / 6.1; // Начальное значение для срока
        input.value = defaultValue;

        // Устанавливаем начальные значения в form
        if (isAmountSlider) {
          this.form.amount = parseInt(defaultValue);
        } else if (isPeriodSlider) {
          this.form.period = parseInt(defaultValue);
        }

        // Обновление значения
        const setValueElem = () => {
          valueElem.innerText = ""; // Убираем текст внутри кружка
          let percent = ((input.value - min) / (max - min)) * 100;
          valueElem.style.left = percent + "%";

          // Обновляем значение в блоке под слайдером
          sliderDisplay.innerText = parseInt(input.value).toLocaleString(
            "ru-RU"
          );

          // Сохраняем значение в form
          if (isAmountSlider) {
            this.form.amount = parseInt(input.value);
          } else if (isPeriodSlider) {
            this.form.period = parseInt(input.value);
          }
        };

        // Инициализация значений
        setValueElem();

        // Добавляем обработчик
        input.addEventListener("input", setValueElem);
      });
    },

    next() {
      const isValidPhone = this.$refs.inputPhone.validate();
      const isValidEmail = this.$refs.inputEmail.validate();
      const isValidFio = this.$refs.inputFio.validate();
      const consent = this.$refs.consentCheckbox.$el;
      const consentText = this.$refs.consentText;

      if (!this.form.consent) {
        consent.classList.add("is-invalid");
        consentText.classList.add("is-invalid-text");
        return true;
      }

      if (!isValidPhone || !isValidEmail || !isValidFio) {
        return true;
      }

      Goal.newBid();
      this.sendPromise = this.sendPromise.then(() => Bid.send(this.form));
      console.log(this.bidId);
   

      window.open("../anketa.html", "_blank");
    },

    //suggest

    fetchFio: debounce(function (search, update) {
      if (!search.length) {
        () => (this.lastnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "fio");
    }, 500),
    fetchFioSuggest(search, update, type) {
      Suggest.suggestFioFull(search, type)
        .then((data) => {
          update(() => {
            this.FioSuggestOptions = data.map((el) => el.text);
          });
        })
        .catch(() => {
          update(() => {
            this.FioSuggestOptions = [search];
          });
        });
    },

    getConsentText() {
      return window.consent || this.consentTextDefault;
    },

    //suggestions
    //Email suggestions
    fetchEmailSuggest: debounce(function (search, update) {
      if (!search.length) {
        () => (this.emailSuggestOptions = []);
        return;
      }
      Suggest.suggestEmail(search)
        .then((data) => {
          update(() => {
            if (data.length)
              this.emailSuggestOptions = data.map((el) => el.text);
            else this.emailSuggestOptions = [search];
          });
        })
        .catch(() => {
          update(() => {
            this.emailSuggestOptions = [search];
          });
        });
    }, 500),
  },

  mounted() {
    this.initializeSliders();
  },
};
</script>
