import * as GetParams from './get_params.js'

var send = function(endpoint, payload) {
    return fetch(endpoint, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': payload.token},
        body: JSON.stringify(payload)
    }).then(response => {
        return response.json();
    }).then(response => {
        window.bid = response;
        localStorage.setItem('bidId', response.id);
        localStorage.setItem('amount', payload.amount);
        localStorage.setItem('period', payload.period);
        localStorage.setItem('firstname', response.firstname);
        return response;
    });
};

var getSubs = function(){
    let params = new URLSearchParams(location.search);
    const subSource = params.get('sub_source');
    const sub1 = params.get('sub1');
    const sub2 = params.get('sub2');
    const sub3 = params.get('sub3');
    const sub4 = params.get('sub4');
    const sub5 = params.get('sub5');
    const sub6 = params.get('sub6');
    return {sub_source:subSource, sub1:sub1, sub2:sub2, sub3:sub3, sub4:sub4, sub5:sub5, sub6:sub6}
}
var getUtms = function() {
    var utms = {};

    if (GetParams.get('utm_source')) {
        utms.utm_source = GetParams.get('utm_source');
    }

    if (GetParams.get('utm_medium')) {
        utms.utm_medium = GetParams.get('utm_medium');
    }

    if (GetParams.get('utm_campaign')) {
        utms.utm_campaign = GetParams.get('utm_campaign');
    }

    if (GetParams.get('utm_term')) {
        utms.utm_term = GetParams.get('utm_term');
    }

    if (GetParams.get('utm_content')) {
        utms.utm_content = GetParams.get('utm_content');
    }

    if (GetParams.getClickId()) {
        utms.click_id = GetParams.getClickId();
    }

    if (GetParams.getWmId()) {
        utms.wm_id = GetParams.getWmId();
    }

    return utms;
};

export { send, getUtms , getSubs }
