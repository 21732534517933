<style scoped>
body.desktop .q-focus-helper{
  width: 0% !important;
}
.login-form__button-change{
	color: #31BB38;
margin-bottom: 15px;
font-family: Jost;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}
.error{
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.login-form__lower-txt a {
  color: #000;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.docs-wrapper {
  margin-left: -10px;
  margin-bottom: 10px;
  margin-top: 5px !important;
}
.line {
  height: 1px;
  border: 1px solid #bbc1c7;
  width: 75px;
}
.q-btn {
  width: 202px;
  padding: 12px 0px !important;
}

.docs-wrapper {
  display: flex;
  align-items: start;
}
.wrapper-lower {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.consent-text {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  
}
.q-field__control {
  max-width: 100%;
}
.bg-green {
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  ) !important;
}
@media (max-width: 500px) {
  .data-text {
    align-items: center;
    padding: 10px 0px;
  }
  .btn-mts:hover:disabled:after,
  .btn-mts:focus:disabled:after {
    left: 9%;
  }
}
</style>
<style lang="scss">
.q-btn--dense.q-btn--round {
  min-height: 20px !important;
  min-width: 22px !important;
}
.q-card > div:not(.q--avoid-card-border) {
  margin-top: 0px !important;
}
.q-card__section--vert {
  padding: 8px 35px !important;
}
.close-popup {
  left: 98%;
  top: -66%;
  background: #d9d9d9 !important;
  width: 9%;
  position: absolute !important;
  width: 15px !important;
  height: 15px !important;
  padding: 0px !important;
  .q-icon {
    font-size: 14px !important;
  }
  .col {
    padding: 0px !important;
  }
}
.q-virtual-scroll__content {
  border-radius: 8px;
  background: #fafafa;
}
.q-item__label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 6px;
  padding-bottom: 6px;
}
.q-item {
  padding: 0 !important;
  min-height: 20px !important;
}
.q-item:hover {
  background: #f2f2f2;
}
.q-menu {
  box-shadow: none !important;
}
.q-virtual-scroll__content {
  margin-top: 6px !important;
}
.q-field__label {
  color: #8b8b8b !important;
}
.block {
  text-transform: none !important;
}
.q-btn--rounded {
  border-radius: 10px !important;
  .block {
    text-transform: none !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.q-btn {
  padding: 4px 3px !important;
  font-size: 12px !important;
}
// .q-field__label {
//   font-size: 12px !important;
//   top: 15px !important;
// }
// .q-field--auto-height .q-field__control {
//   height: 50px !important;
//   min-height: 50px !important;
// }
.q-field__control {
  height: 50px !important;
  min-height: 50px !important;
}
.q-field--labeled .q-field__native {
  padding-top: 12px !important;
  font-size: 12px !important;
}
.q-field__marginal {
  height: 50px !important;
}
.q-btn:before {
  box-shadow: none !important;
}
.q-dialog__inner > .q-card > .q-card__actions .q-btn--rectangle {
  border-radius: 10px;
}
@media (max-width: 470px) {
  .input {
    .q-field--outlined .q-field__control {
      padding: 0 18px 0 12px !important;
    }
  }
  .q-item__label {
    font-size: 16px !important;
    padding-left: 10px !important;
  }
  .q-field__append + .q-field__append {
    padding-left: 1px !important;
  }
}
.q-card__actions--horiz {
  justify-content: center !important;
}
.q-field__native {
  padding: 0 0 !important;
}
.q-field--outlined.q-field--rounded .q-field__control {
  border-radius: 10px !important;
}
.q-field--outlined .q-field__control:before {
  border: 1px solid #8b8b8b !important;
}
.phone-confirm-input {
  .q-field__native,
  .q-field__input {
    text-align: center;
  }
}

.q-checkbox__bg {
  width: 40% !important;
  height: 40% !important;

}
.q-field--with-bottom {
  padding-bottom: 10px !important;
  
}
.q-field__bottom--animated {
  bottom: -12px !important;
}
</style>
<style lang="scss">
.q-field__inner {
}
.steps-bg {
  background: #fafafa;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.error {
  margin-bottom: 10px;
}

.q-field__messages {
  position: absolute !important;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.input-wrap {
  flex-direction: column;
}

.docs-link {
  color: #000;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.required-star {
  font-weight: bold;
}
.q-stepper__title {
  margin-left: 5px;
}
.q-field__native {
  padding-bottom: 0 !important;
}

.text-body2 {
  font-size: 20px !important;
  margin-left: -30px;
}
.wrap-percent {
  display: flex;
  flex-direction: column;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}
.wrapper-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-number {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 25px;
  color: #31bb38;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.change-number:hover {
  text-decoration: none;
  color: #26912b;
}

.q-stepper__nav {
  text-align: right;
}

.q-dialog__backdrop {
  backdrop-filter: blur(3px);
}
.confirm-retries-text {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}

.loan-title {
  font-family: Jost;
  font-size: 32px;
  font-weight: 400;
  line-height: 57.6px;
  text-align: center;
}
.phone-style {
  color: #35c12c;
  margin-top: 8px;
  margin-bottom: 8px;
}
.your-loan {
  font-size: 28px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  .loan-params {
    font-size: 22px;
    font-weight: 500;
    margin-left: 0px;
  }
  .loan-params:nth-child(2) {
    margin-left: 5px;
  }
}
.loan-subtitle {
  font-family: Jost;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.23px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
}

.consent-text-title {
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
}
.consent-text-subtitle {
  font-size: 10px;
  padding-bottom: 15px;
}
.step-wrapper {
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  position: relative;
}
.step-wrapper-percent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.second-step-bg,
.third-step-bg {
  background: #d2d2d2;
  border-radius: 50%;
  padding: 15px 30px;
  font-size: 28px;
  color: white;
}

.first-line,
.second-line {
  top: 15px;
  position: relative;
}

.step-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
}
.step-title-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.step-title-2 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.54);
}

.confirm-button-next {
  margin-top: 15px;
  margin-bottom: 25px;
}
.q-card > div:not(.q--avoid-card-border) {
  margin-top: -15px;
}

.confirm-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
.confirm-button-wrapper-txt {
  width: 21%;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}
.confirm-button-wrapper-txt .confirm-button {
}
.data-text-lower {
  padding: 8px 85px 8px 15px;
  background: #fafafa;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  right: -21%;
}
.data-text-top {
  display: none;
  background: #fafafa;
  margin-top: 15px;
  position: relative;
}
.data-sum,
.data-period {
  display: flex;
  align-items: center;
  margin-bottom: -5px;
}

.data-title {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.data-subtitle {
  font-size: 12px;
  background-image: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: underline;
  text-decoration-color: #35c12c;
  text-underline-offset: 4px;
  margin-bottom: 8px;
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
}
.custom-ml {
  margin-left: 23px;
}
.percentage-1,
.percentage-2,
.percentage-3 {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  font-style: normal;
  font-weight: 500;
}
.confirm-title {
  font-size: 16px;
  margin-bottom: 25px;
  font-weight: 600;
  margin-top: 25px;
}

.confirm-title-second {
  color: #000;

  font-family: Jost;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.confirm-subtitle {
  font-size: 12px;
}
.phone-style {
  font-size: 18px;
  font-weight: 600;
}

.phone-confirm-input {
  .q-field__inner {
    width: 100% !important;
  }
  .code-input {
    .q-field__inner {
      display: flex;
      align-items: center;
      flex-direction: column;
      .q-field__control {
        width: 90% !important;
      }
    }
  }
}
button:disabled,
button[disabled] {
  opacity: 1 !important;
}
.btn-mts {
  background: #1d2023;
  border-radius: 10px;
  padding: 14px 22px;
  width: 202px;
  cursor: pointer;
  display: block;
  position: relative;
}
.confirm-button-wrapper-mts {
  display: contents;
}

.registration-btn:hover:disabled:after {
  background: rgba(36, 36, 36, 0.85);
  border-radius: 10px;
  padding: 9px 9px;
  content: "Проставьте согласие в чекбоксе";
  display: block;
  position: absolute;
  top: -68%;
  left: 0%;
  font-size: 8px;
  font-weight: 300;
  width: 100%;
  line-height: normal;
}
.registration-btn:focus:disabled:after {
  background: rgba(36, 36, 36, 0.85);
  border-radius: 10px;
  padding: 9px 9px;
  content: "Проставьте согласие в чекбоксе";
  display: block;
  position: absolute;
  top: -68%;
  left: 0%;
  font-size: 8px;
  font-weight: 300;
  width: 100%;
  line-height: normal;
}
.btn-mts:hover:disabled:after {
  background: rgba(36, 36, 36, 0.85);
  border-radius: 10px;
  padding: 9px 9px;
  content: "Проставьте согласие в чекбоксе";
  display: block;
  position: absolute;
  top: -60%;
  left: 0%;
  font-size: 8px;
  font-weight: 300;
  width: 100%;
  line-height: normal;
  color: white;
  text-transform: uppercase;
}
.btn-mts:focus:disabled:after {
  background: rgba(36, 36, 36, 0.85);
  border-radius: 10px;
  padding: 9px 9px;
  content: "Проставьте согласие в чекбоксе";
  display: block;
  position: absolute;
  top: -60%;
  left: 0%;
  font-size: 8px;
  font-weight: 300;
  width: 70%;
  line-height: normal;
  color: white;
}

.mts-txt {
  position: absolute;
  left: 45%;
  font-size: 12px;
  display: block;
}
.mts-txt-mob {
  font-size: 12px;
  display: none;
}
//media
@media (max-width: 1301px) {
  .mts-txt {
    left: 44%;
  }
}
@media (max-width: 1101px) {
  .mts-txt {
    left: 43%;
  }
}
@media (max-width: 901px) {
  .mts-txt {
    left: 41%;
  }
}
@media (max-width: 649px) {
  .phone-confirm-input {
    .q-field__inner {
      width: 100% !important;
    }
  }
  .confirm-button-wrapper-txt {
    width: 100%;
  }

  .q-field__control {
    max-width: 100% !important;
  }
  .confirm-button-wrapper {
    flex-direction: column;
  }
  .data-text {
    position: unset;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .data-text-lower {
    display: none;
  }
  .data-title {
    font-size: 12px;
  }
  .data-subtitle {
    font-size: 14px;
  }

  .step-title {
    justify-content: center;
  }
  .consent-text-title {
    font-size: 14px;
  }
  .consent-text-subtitle {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .q-field--outlined .q-field__control:before {
  }
  .confirm-retries-text {
    font-size: 14px;
  }

  .change-number {
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  .q-stepper__header {
    flex-direction: column;
    align-items: center !important;
  }

  .required-star {
    margin-top: 5px;
  }
  .confirm-button {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 398px) {
  .text-h6 {
    font-size: 18px !important;
  }

  .consent-text {
    font-size: 10px !important;
  }
}

.confirm-retries-text {
  margin-top: -20px;
}

.change-number-btn {
  margin-top: -20px;
}

.q-card {
  overflow: unset !important;
}

button {
  outline: none;
  border: none;
  background: transparent;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 85%;
}
.is-invalid .q-checkbox__bg{
  border: 2px solid #C10015;
}
.docs-wrapper .is-invalid-text{
  color: #C10015;
}
.is-invalid-text a{
  color: #C10015;
}
.docs-wrapper a{
  text-decoration: underline;
}
.is-invalid-text, .is-invalid{
    animation: shake .3s;
}

@keyframes shake {
    25% {transform: translateX(3px);}
    55% {transform: translateX(-3px);}
    75% {transform: translateX(3px);}
}
</style>

<template>
  <form ref="myForm">
    <div v-if="!phoneConfirm" class="loan-title">Регистрация</div>
    <div v-if="!phoneConfirm" class="loan-subtitle">
      Введите номер своего мобильного телефона и электронную почту для получения
      доступа в личный кабинет.
    </div>

    <div v-if="!phoneConfirm" class="input-wrapper">
      <q-input
        :rules="phoneValidate"
        rounded
        outlined
        ref="inputPhone"
        placeholder="+7 (901) 234-5678"
        v-model="form.phone"
        label="Телефон"
        class="input-req input"
        mask="+7 (###) ###-####"
        :color="themeColor"
      />

      <q-select
        :color="themeColor"
        :rules="emailValidate"
        :model-value="form.email"
        use-input
        hide-selected
        @input-value="(val) => (form.email = val)"
        fill-input
        rounded
        class="input-req"
        ref="inputEmail"
        aria-expanded="true"
        outlined
        label="Email"
        :options="emailSuggestOptions"
        @filter="fetchEmailSuggest"
        new-value-mode="add"
        placeholder="example@mail.ru"
        behavior="menu"
      >
      </q-select>

      <div class="wrapper-lower">
        <div class="confirm-button-wrapper">
          <div class="confirm-button-main">
            <q-btn
              label="РЕГИСТРАЦИЯ"
              
              :color="themeColor"
              :ripple="{ center: true }"
              rounded
              class="bg-green registration-btn"
              @click="phoneConfirmHandler()"
            />
          </div>
        </div>
        <div class="confirm-button-wrapper-txt">
          <div class="line-wrap confirm-button text-center">
            <div class="line"></div>
            <div class="seperator">или</div>
            <div class="line"></div>
          </div>
        </div>

        <div class="confirm-button-wrapper">
          <button
            
            @click.prevent="() => oauth2('mts')"
            class="btn-mts"
          >
            <img src="/img/mts.png" alt="mts" />
          </button>
        </div>
        <div class="docs-wrapper">
          <q-checkbox ref="consent" @click="removeClass"  v-model="form.consent" id="consent" />
          <div class="consent-text" id="consentText" v-html="getConsentText()"></div>
        </div>
        <div class="error">{{ error }}</div>
      </div>
    </div>
    <div
      v-else
      class="phone-confirm-input d-flex flex-column align-items-center"
    >
      <div class="text-center d-flex flex-column align-items-center">
        <div v-if="num" class="confirm-title-second">
          Введите новый номер телефона
        </div>
        <div v-if="!num" class="confirm-subtitle">
          Вам отправлен код на номер:
        </div>

        <div class="phone-style">{{ form.phone }}</div>

        <div class="text-center d-flex flex-column align-items-center">
          <a
            role="button"
            v-on:click.prevent="showNum"
            v-if="!num"
            class="login-form__button-change"
            href="#"
            >Изменить</a
          >
          <div class="change-phone-wrapper"></div>
          <q-input
            v-model="form.phone"
            :rules="phoneValidate"
            rounded
            outlined
            placeholder="+7 (901) 234-5678"
            mask="+7 (###) ###-####"
            :color="themeColor"
            v-if="num"
            style="margin-top: 10px"
            class="d-flex flex-column align-items-center"
          >
            <template v-slot:after>
              <q-btn
                style="
                  text-transform: none !important;
                  font-size: 12px !important;
                  margin-top: -10px;
                "
                v-on:click="hideNum"
                class="change-number-btn"
                round
                dense
                flat
                label="Готово"
              />
            </template>
          </q-input>
        </div>
      </div>
      <div class="code-input">
        <q-input
          v-if="showCode"
          v-model="code"
          autocomplete="off"
          style=""
          rounded
          outlined
          mask="#####"
          placeholder="_ _ _ _ _"
          :color="themeColor"
        />
      </div>

      <div class="text-negative error" v-if="error !== null">{{ error }}</div>
      <div v-if="showCode" class="login-form__lower-txt text-center" style="">
        <div
          class="login-form__lower-txt"
          v-if="timeDifference >= 0 && retry >= 10"
        >
          <span>{{ timerOutput }}</span>
        </div>
        <div
          class="login-form__lower-txt"
          v-if="timeDifference >= 0 && retry < 10"
        >
          Получить новый код можно через {{ timerOutput }}
        </div>
        <div
          v-if="timeDifference <= 0 && retry < 10"
          class="login-form__lower-txt"
        >
          <a
            href="#"
            class=""
            role="button"
            @click="phoneConfirmRetryHandler()"
            >Получить код повторно</a
          >
        </div>
      </div>
      <q-btn
        @click="confirm"
        :ripple="{ center: true }"
        rounded
        :color="themeColor"
        label="Продолжить"
        class="confirm-button-next"
      />
    </div>
  </form>
</template>

<script>
import * as Auth from "@/assets/js/auth.js";
import { debounce } from "debounce";
import vSelect from "@/../node_modules/vue-select/src/index.js";
import * as Bid from "@/assets/js/bid/default_create.js";
import * as ConfirmTel from "@/assets/js/confirm_tel.js";
import * as Suggest from "@/assets/js/suggest.js";
import * as Validate from "@/assets/js/validators.js";
import * as GetParams from "@/assets/js/get_params.js";
import * as DataParams from "@/assets/js/data_params.js";
import * as Goal from "@/assets/js/goal.js";

export default {
  name: "FormDefaultNewStepOne",

  components: { vSelect },

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
    previousPagePromise: Promise,
  },

  data: () => ({
    phone: "",
    code: "",
    isShow: true,
    isNextClicked: false,
    num: false,
    isHide: false,
    phoneValid: true,
    error: null,
    page: "phone",
    isReadonly: true,
    countDownToTime: 0,
    timeDifference: 0,
    timerOutput: null,
    interval: null,
    retry: 0,

    docs: false,
    num: false,
    phoneConfirm: false,
    showRepeatBtn: false,
    showCode: true,
    consentTextDefault:
      '<a href="docs/Согласие_на_получение_информационой_и_рекламной_рассылки.pdf" target="_blank"> рекламной и информационной рассылок</a>',
    themeColor: DataParams.get("color"),
    isNextClicked: false,
    isPartialSubmitted: false,
    sendPromise: Promise.resolve(),
    emailSuggestOptions: [],
    form: {
      phone: null,
      email: null,
      consent: false,
      amount: GetParams.get("amount") || 10000,
      period: GetParams.get("period") || 30,
    },
    valid: {
      phone: true,
      email: true,
      consent: true,
    },
  }),

  computed: {
    //validation
    phoneValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите телефон",
        (val) => Validate.phone(val) || "Введите полностью",
      ];
    },
    emailValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите email",
        (val) => Validate.email(val) || "Введите полностью",
      ];
    },

    formatSum() {
      return this.form.amount.toLocaleString("ru-RU");
    },
  },

  watch: {
    timeDifference(val) {
      if (val < 2) {
        clearInterval(this.interval);
      }
    },
  },
  methods: {
    oauth2(client) {
      
     
      const consent = document.getElementById("consent");
      const consentText = document.getElementById("consentText");
      if (!this.form.consent) {
        consent.classList.add("is-invalid");
        consentText.classList.add("is-invalid-text");
        return true;
      }
      
   
    
      Auth.oauth2(client);
    },
   
    //phone confirm
     phoneConfirmHandler() {
      const isValidPhone =  this.$refs.inputPhone.validate();
      const isValidEmail =  this.$refs.inputEmail.validate();
     
      const consent = document.getElementById("consent");
      const consentText = document.getElementById("consentText");
      if (!this.form.consent) {
        consent.classList.add("is-invalid");
        consentText.classList.add("is-invalid-text");
        return true;
      }
      
   
      if (!isValidPhone || !isValidEmail) {
        return true;
      }
      this.phoneConfirm = true;
      this.startTimer();
      this.retry++;

      const resp =  ConfirmTel.entry(
        this.form.phone,
        this.form.email,
        this.form.consent,
        this.form.amount,
        this.form.period
      );
      if (resp.success == false) {
        this.error = resp.error;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    removeClass() {
      if(this.form.consent){
      const consent = document.getElementById("consent");
      const consentText = document.getElementById("consentText");
      consent.classList.remove("is-invalid");
      consentText.classList.remove("is-invalid-text");
    }
     
    },
    async phoneConfirmRetryHandler() {
      this.startTimer();
      this.retry++;

      this.sendPromise = ConfirmTel.entry(
        this.form.phone,
        this.form.email,
        this.form.consent,
        this.form.amount,
        this.form.period
      );
    },
    async confirm() {
      const response = await ConfirmTel.confirm(this.code);

      this.error = response.error;
      if (response.error === null) {
        if (response.exists) {
          return ConfirmTel.confirm_cab().then(({ url }) =>
            location.replace(url)
          );
        }
        Goal.newBid();
        this.$store.commit("updateAnketa", {
          period: +this.form.period,
          amount: +this.form.amount,
        });
        this.forwardPage(this.sendPromise);
      }
    },
    startTimer() {
      this.countDownToTime = new Date().getTime() + 10000;
      this.interval = setInterval(() => {
        const timeNow = new Date().getTime();
        this.timeDifference = this.countDownToTime - timeNow;
        const millisecondsInOneSecond = 1000;
        const millisecondsInOneMinute = millisecondsInOneSecond * 60;
        const millisecondsInOneHour = millisecondsInOneMinute * 60;
        const millisecondsInOneDay = millisecondsInOneHour * 24;
        const differenceInDays = this.timeDifference / millisecondsInOneDay;
        const remainderDifferenceInHours =
          (this.timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
        const remainderDifferenceInMinutes =
          (this.timeDifference % millisecondsInOneHour) /
          millisecondsInOneMinute;
        const remainderDifferenceInSeconds =
          (this.timeDifference % millisecondsInOneMinute) /
          millisecondsInOneSecond;
        const remainingDays = Math.floor(differenceInDays);
        const remainingHours = Math.floor(remainderDifferenceInHours);
        const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
        const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
        this.timerOutput =
          (remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes) +
          ":" +
          (remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds);
      }, 1);
    },
    showNum() {
      this.num = true;
      this.showCode = false;
    },
    hideNum() {
      this.num = false;
      this.showCode = true;
    },
    getConsentText() {
      return window.consent || this.consentTextDefault;
    },
    //suggestions
    //Email suggestions
    fetchEmailSuggest: debounce(function (search, update) {
      if (!search.length) {
        () => (this.emailSuggestOptions = []);
        return;
      }
      Suggest.suggestEmail(search)
        .then((data) => {
          update(() => {
            if (data.length)
              this.emailSuggestOptions = data.map((el) => el.text);
            else this.emailSuggestOptions = [search];
          });
        })
        .catch(() => {
          update(() => {
            this.emailSuggestOptions = [search];
          });
        });
    }, 500),
  },

  mounted() {
    document.body.style.setProperty("--q-primary", "#31BB38");
  },
};
</script>
