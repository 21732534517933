<style scoped>
.q-field__inner {
  width: 277px;
}
.q-field__control {
  max-width: 94%;
}
.bg-green {
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  ) !important;
}
@media (max-width: 500px) {
  .data-text {
    align-items: center;
    padding: 10px 0px;
  }
}
.row.inline {
  display: flex !important;
}
</style>
<style lang="scss">
.q-btn--dense.q-btn--round {
  min-height: 20px !important;
  min-width: 22px !important;
}
.q-card > div:not(.q--avoid-card-border) {
  margin-top: 0px !important;
}
.q-card__section--vert {
  padding: 8px 35px !important;
}
.close-popup {
  left: 98%;
  top: -66%;
  background: #d9d9d9 !important;
  width: 9%;
  position: absolute !important;
  width: 15px !important;
  height: 15px !important;
  padding: 0px !important;
  .q-icon {
    font-size: 14px !important;
  }
  .col {
    padding: 0px !important;
  }
}
.q-virtual-scroll__content {
  border-radius: 8px;
  background: #fafafa;
}
.q-item__label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 6px;
  padding-bottom: 6px;
}
.q-item {
  padding: 0 !important;
  min-height: 20px !important;
}
.q-item:hover {
  background: #f2f2f2;
}
.q-menu {
  box-shadow: none !important;
}
.q-virtual-scroll__content {
  margin-top: 6px !important;
}
.q-field__label {
  color: #8b8b8b !important;
}
.block {
  text-transform: none !important;
}
.q-btn--rounded {
  border-radius: 10px !important;
  .block {
    text-transform: none !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.q-btn {
  padding: 4px 3px !important;
  font-size: 12px !important;
}
// .q-field__label {
//   font-size: 12px !important;
//   top: 15px !important;
// }
// .q-field--auto-height .q-field__control {
//   height: 50px !important;
//   min-height: 50px !important;
// }
.q-field__control {
  height: 50px !important;
  min-height: 50px !important;
}
.q-field--labeled .q-field__native {
  padding-top: 12px !important;
  font-size: 12px !important;
}
.q-field__marginal {
  height: 50px !important;
}
.q-btn:before {
  box-shadow: none !important;
}
.q-dialog__inner > .q-card > .q-card__actions .q-btn--rectangle {
  border-radius: 10px;
}
@media (max-width: 470px) {
  .input {
    .q-field--outlined .q-field__control {
      padding: 0 18px 0 12px !important;
    }
  }
  .q-item__label {
    font-size: 16px !important;
    padding-left: 10px !important;
  }
  .q-field__append + .q-field__append {
    padding-left: 1px !important;
  }
}
.q-card__actions--horiz {
  justify-content: center !important;
}
.q-field__native {
  padding: 0 0 !important;
}
.q-field--outlined.q-field--rounded .q-field__control {
  border-radius: 10px !important;
}
.q-field--outlined .q-field__control:before {
  border: 1px solid #8b8b8b !important;
}
.phone-confirm-input {
  .q-field__native,
  .q-field__input {
    text-align: center;
  }
}

.q-checkbox__bg {
  width: 40% !important;
  height: 40% !important;
}
.q-field--with-bottom {
  padding-bottom: 10px !important;
}
</style>
<style lang="scss">
.steps-bg {
  background: #fafafa;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.error {
  margin-bottom: 10px;
}
.wrapper-lower {
  display: flex;
  flex-direction: column;
}
.docs-wrapper {
  margin-left: -10px;
  margin-bottom: 10px;
}
.q-field__messages {
  position: absolute !important;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.input-wrap-property {
  display: flex;
  flex-direction: row;
}
.input-wrap {
  flex-direction: column;
}
.docs-wrapper {
  display: flex;
  align-items: center;
}
.docs-link {
  color: #000;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.required-star {
  font-weight: bold;
}
.q-stepper__title {
  margin-left: 5px;
}
.q-field__native {
  padding-bottom: 0 !important;
}

.text-body2 {
  font-size: 20px !important;
  margin-left: -30px;
}
.wrap-percent {
  display: flex;
  flex-direction: column;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}
.wrapper-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-number {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 25px;
  color: #31bb38;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.change-number:hover {
  text-decoration: none;
  color: #26912b;
}

.q-stepper__nav {
  text-align: right;
}

.q-dialog__backdrop {
  backdrop-filter: blur(3px);
}
.confirm-retries-text {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}


.phone-style {
  color: #35c12c;
  margin-top: 8px;
  margin-bottom: 8px;
}
.your-loan {
  font-size: 28px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  .loan-params {
    font-size: 22px;
    font-weight: 500;
    margin-left: 0px;
  }
  .loan-params:nth-child(2) {
    margin-left: 5px;
  }
}

.consent-text {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.consent-text-title {
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
}
.consent-text-subtitle {
  font-size: 10px;
  padding-bottom: 15px;
}
.step-wrapper {
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  position: relative;
}
.step-wrapper-percent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.second-step-bg,
.third-step-bg {
  background: #d2d2d2;
  border-radius: 50%;
  padding: 15px 30px;
  font-size: 28px;
  color: white;
}

.first-line,
.second-line {
  top: 15px;
  position: relative;
}

.step-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

.step-title-second {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 0px;
}

.step-title-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.step-title-2 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.54);
}

.confirm-button-next {
  margin-top: 15px;
  margin-bottom: 15px;
}
.q-card > div:not(.q--avoid-card-border) {
  margin-top: -15px;
}

.confirm-button-wrapper-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.data-text-lower {
  padding: 8px 85px 8px 15px;
  background: #fafafa;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  right: -21%;
}
.data-text-top {
  display: none;
  background: #fafafa;
  margin-top: 15px;
  position: relative;
}
.data-sum,
.data-period {
  display: flex;
  align-items: center;
  margin-bottom: -5px;
}

.data-title {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.data-subtitle {
  font-size: 12px;
  background-image: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: underline;
  text-decoration-color: #35c12c;
  text-underline-offset: 4px;
  margin-bottom: 8px;
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
}
.custom-ml {
  margin-left: 21px;
}
.percentage-1,
.percentage-2,
.percentage-3 {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  font-style: normal;
  font-weight: 500;
}
.confirm-title {
  font-size: 16px;
  margin-bottom: 25px;
  font-weight: 600;
  margin-top: 25px;
}



.phone-style {
  font-size: 18px;
  font-weight: 600;
}

.phone-confirm-input {
  .q-field__inner {
    width: 100% !important;
  }
  .code-input {
    .q-field__inner {
      display: flex;
      align-items: center;
      flex-direction: column;
      .q-field__control {
        width: 90% !important;
      }
    }
  }
}
.change-number {
  font-size: 16px;
}
.retry-output {
  font-size: 16px;
}
.confirm-subtitle {
  font-size: 14px;
}
//media
@media (max-width: 649px) {
  .phone-confirm-input {
    .q-field__inner {
      width: 100% !important;
    }
  }

  .q-field__control {
    max-width: 100% !important;
  }
  .confirm-button-wrapper-1 {
    flex-direction: column;
  }
  .data-text {
    position: unset;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .data-text-lower {
    display: none;
  }
  .data-title {
    font-size: 12px;
  }
  .data-subtitle {
    font-size: 14px;
  }

  .step-title,
  .step-title-second {
    justify-content: center;
  }
  .consent-text-title {
    font-size: 14px;
  }
  .consent-text-subtitle {
    font-size: 12px;
  }
  .properties-data{
    display: block !important;
    flex-direction: column;
  }
  .properties-wrap{
    align-items: center;
  }
  .form-title{
    text-align: center;
  }
}
@media (max-width: 500px) {



  .step-title {
    margin-bottom: 10px;
  }
 
  .your-loan {
    font-size: 22px;
    .loan-params {
      font-size: 18px;
    }
  }


  .step-title-1 {
    font-size: 12px;
  }
  .step-title-2 {
    font-size: 12px;
  }


  .percentage-1,
  .percentage-2,
  .percentage-3 {
    font-size: 10px;
  }

  .first-line {
    top: 10px;
  }
  .first-line-wrapper {
    text-align: center;
  }
}
@media (max-width: 430px) {
  .q-stepper__header {
    flex-direction: column;
    align-items: center !important;
  }

  .required-star {
    margin-top: 5px;
  }
  .confirm-button {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
@media (max-width: 398px) {
  .text-h6 {
    font-size: 18px !important;
  }


  .retry-output {
    font-size: 12px;
  }
  .confirm-subtitle {
    font-size: 12px;
  }
}
.q-item__section--main {
  padding-left: 10px;
}
.confirm-retries-text {
  margin-top: -20px;
}
.q-field__after,
.q-field__append {
  padding-left: 0px !important;
}
.phone-confirm-input {
  .q-field--with-bottom {
    padding-bottom: 0 !important;
    margin-bottom: -20px !important;
  }
}
.change-number-btn {
  margin-top: -20px;
}
.text-green {
  color: #31bb38 !important;
}

.q-card {
  overflow: unset !important;
}
.q-radio__inner {
  font-size: 26px !important;
  width: 36px !important;
}
.input-wrap-property {
  margin-left: -10px !important;
}
.q-radio__bg path {
  fill: #808080 !important;
}
.step-text {
  color: #808080;
  font-family: "Jost";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.q-radio__label{
  margin-left: -5px;
  font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
color: #808080 !important;
}
.radio-custom{
  margin-left: 24px !important;
}
.properties-data{
  display: flex;
}
</style>

<template>
  <form action="" ref="myForm">
    <div class="form-title">
      Данные
    </div>
    <div class="wrapper first-step">
      <div class="input-wrap">
        <q-select
          :color="themeColor"
          :rules="lastnameValidate"
          v-model="form.lastname"
          use-input
          rounded
          outlined
          hide-selected
          @input-value="(val) => (form.lastname = val)"
          fill-input
          input-debounce="0"
          label="Фамилия"
          placeholder="Иванов"
          class="input-req"
          :options="lastnameSuggestOptions"
          @filter="fetchLastname"
          ref="inputLastname"
          new-value-mode="add"
          behavior="menu"
        >
        </q-select>
      </div>
      <div class="input-wrap">
        <q-select
          :color="themeColor"
          :rules="firstnameValidate"
          v-model="form.firstname"
          use-input
          rounded
          outlined
          hide-selected
          fill-input
          class="input-req"
          @input-value="(val) => (form.firstname = val)"
          input-debounce="0"
          label="Имя"
          :options="firstnameSuggestOptions"
          @filter="fetchFirstname"
          new-value-mode="add"
          placeholder="Иван"
          behavior="menu"
          ref="inputFirstname"
        >
        </q-select>
      </div>
    </div>

    <div class="wrapper first-step">
      <div class="input-wrap">
        <q-select
          :color="themeColor"
          :rules="secondnameValidate"
          v-model="form.secondname"
          use-input
          rounded
          outlined
          hide-selected
          class="input-req"
          fill-input
          @input-value="(val) => (form.secondname = val)"
          input-debounce="0"
          label="Отчество"
          :options="secondnameSuggestOptions"
          @filter="fetchSecondname"
          new-value-mode="add"
          placeholder="Иванович"
          behavior="menu"
          ref="inputSecondname"
        >
        </q-select>
      </div>
      <div class="input-wrap">
        <q-input
          :rules="phoneValidate"
          rounded
          outlined
          ref="inputPhone"
          placeholder="+7 (901) 234-5678"
          v-model="form.phone"
          label="Телефон"
          class="input-req input"
          mask="+7 (###) ###-####"
          :color="themeColor"
        />
      </div>
    </div>
    <div class="properties-data align-items-center first-step">
      <div class="input-wrap">
        <q-select
          :color="themeColor"
          v-model="form.debt"
          :rules="debtValidate"
          use-input
          rounded
          outlined
          hide-selected
          class="input-req"
          fill-input
          input-debounce="0"
          label="Сумма долга"
          :options="debtItems"
          emit-value
          map-options
          behavior="menu"
          ref="inputDebt"
        >
        </q-select>
      </div>
      <div class="input-wrap properties-wrap">
        <div class="step-text">Есть ли у вас имущество</div>
        <div class="input-wrap-property">
          <q-radio  color="green" v-model="form.property" val="1" label="Да" />
          <q-radio  class="radio-custom" color="green" v-model="form.property" val="0" label="Нет" />
          {{ error }}
        </div>
      </div>
    </div>

    <div class="confirm-button">
      <q-btn
        @click="nextStep()"
        :ripple="{ center: true }"
        rounded
        :color="themeColor"
        label="Отправить заявку"
        class="confirm-button-next"
      />
    </div>
  </form>
</template>

<script>
import { debounce } from "debounce";
import * as Bid from "@/assets/js/bid/bankruptcy_create.js";
import * as Validate from "@/assets/js/validators.js";
import * as Goal from "@/assets/js/goal.js";
import * as DataParams from "@/assets/js/data_params.js";
import * as Suggest from "@/assets/js/suggest.js";
export default {
  name: "FormBancruptcy",

  props: {
    formType: String,
    forwardPage: Function,
    backwardPage: Function,
  },

  data: () => ({
    sendPromise: Promise.resolve(),
    firstnameSuggestOptions: [],
    lastnameSuggestOptions: [],
    secondnameSuggestOptions: [],
    consentTextDefault:
      'Даю свое согласие на <a href="/docs/Soglasie_na_obrabotku_personalnyh_dannyh.pdf" target="_blank">обработку персональных данных</a>, на <a href="/docs/Soglasie_na_poluchenie_reklamnyh_materialov.pdf" target="_blank">получение</a> рекламных материалов. Соглашаюсь с условиями <a href="/docs/Oferta.pdf" target="_blank">оферты</a>. Я принял соглашение на оформление <a href="/docs/Prilozhenie_4_Soglashenie_na_oformlenie_platnoj_podpiski.pdf" target="_blank">платной подписки</a>. Для активации сервиса будет списан 1 рубль, дальнейшее использование сервиса будет проходит в соответствии с <a href="/docs/Prilozhenie_2_Informacija_o_dejstvujushhih_tarifah.pdf" target="_blank">тарифами сервиса</a>. Стоимость услуги четыреста девяносто восемь рублей каждые 5 дней по подписке. Подписка не гарантирует получение займа!',
    isNextClicked: false,
    sendPromise: Promise.resolve(),
    form: {
      phone: null,
      firstname: null,
      lastname: null,
      secondname: null,
      debt: null,
      property: null,
      
    },
    error: null,
    debtItems: [
      { value: 0, label: "от 0 до 500 000 рублей" },
      { value: 1, label: "более 500 000 рублей" },
    ],
    propertyItems: [
      { id: 1, value: "Да" },
      { id: 0, value: "Нет" },
    ],
    themeColor: DataParams.get("color"),
  }),

  computed: {
    //validation
    phoneValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите телефон",
        (val) => Validate.phone(val) || "Введите полностью",
      ];
    },
    debtValidate() {
      return [
        (val) => (val !== null && val !== "") || "Выберите сумму долга",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
  
    lastnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите фамилию",
        (val) => Validate.textOnly(val) || "Введите корректно",
      ];
    },
    firstnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите имя",
        (val) => Validate.textOnly(val) || "Введите корректно",
      ];
    },
    secondnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите отчество",
        (val) => Validate.textOnly(val) || "Введите корректно",
      ];
    },
  },

  methods: {
    getConsentText() {
      return window.consent || this.consentTextDefault;
    },
    //Нажатие на кнопку "продолжить"

    //Валидация формы

    async nextStep() {
      const isValidLastname = await this.$refs.inputLastname.validate();
      const isValidFirstname = await this.$refs.inputFirstname.validate();
      const isValidSecondname = await this.$refs.inputSecondname.validate();
      const isValidPhone = await this.$refs.inputPhone.validate();
      const isValidDebt = await this.$refs.inputDebt.validate();
      if (!Validate.noempty(this.form.property)) {
        this.error = 'Выберите значение';
        return
      }
      
      if (
        !isValidLastname ||
        !isValidPhone ||
        !isValidFirstname ||
        !isValidSecondname ||
        !isValidDebt
        
      ) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
      const resp = Bid.send(this.form);
      console.log(resp);
      this.forwardPage(this.sendPromise);
      this.isNextClicked = true;
    },
    fetchLastname: debounce(function (search, update) {
      if (!search.length) {
        () => (this.lastnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "surname");
    }, 500),

    fetchFirstname: debounce(function (search, update) {
      if (!search.length) {
        () => (this.firstnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "name");
    }, 500),

    fetchSecondname: debounce(function (search, update) {
      if (!search.length) {
        () => (this.secondnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "patronymic");
    }, 500),

    fetchFioSuggest(search, update, type) {
      Suggest.suggestFio(search, type)
        .then((data) => {
          update(() => {
            if (type === "name") {
              this.firstnameSuggestOptions = data.map((el) => el.text);
            }
            if (type === "surname") {
              this.lastnameSuggestOptions = data.map((el) => el.text);
            }
            if (type === "patronymic") {
              this.secondnameSuggestOptions = data.map((el) => el.text);
            }
          });
        })
        .catch(() => {
          update(() => {
            if (type === "name") {
              this.firstnameSuggestOptions = [search];
            }
            if (type === "surname") {
              this.lastnameSuggestOptions = [search];
            }
            if (type === "patronymic") {
              this.secondnameSuggestOptions = [search];
            }
          });
        });
    },
  },
  watch: {
    'form.property': function () {
      this.error = '';
    }
  },
};
</script>
