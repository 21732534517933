/*
 * Модуль для отправки данных формы на сервер
 */

import * as Sender from './../sender.js'
import * as DataParams from './../data_params.js'

var getEndpointUrl = function() {
    const bidId = window.bid.id || localStorage.getItem('bidId');
    if(DataParams.get('token')){
        return process.env.VUE_APP_API_BASE_URL + '/api/webmaster/bid';
    }
    if (!bidId) {
        return process.env.VUE_APP_API_BASE_URL + '/api/bid/create';
    } else {
        return process.env.VUE_APP_API_BASE_URL + '/api/bid/create?id=' + bidId;
    }
};

var dateConvert = function(date) {
    const re = /^\d\d\d\d-\d\d-\d\d$/;
    if (re.test(date)) {
        return date;
    }
    const regExp = /^\d\d.\d\d.\d\d\d\d$/;
    if (!regExp.test(date)) {
        return null;
    }
    var dobParts = date.split('.');
    return dobParts[2] + '-' + dobParts[1] + '-' + dobParts[0];
};

var getRequestPayload = function(form) {
    return {
        phone: form.phone,
        email: form.email,
        firstname: form.firstname,
        lastname: form.lastname,
        secondname: form.secondname,
        fio: form.fio,
        amount: parseInt(form.amount),
        period: parseInt(form.period),
        dob: dateConvert(form.dob),
        host: window.location.hostname,
        token: DataParams.get('token'),
        sex: form.sex,
        cityKladrId: form.cityKladr,
        regionKladrId: form.regionKladr,
        creditHistory: form.creditHistory,
        consent: form.consent,
        consentSecond: form.consentSecond,
        passport: {
            birthPlace: form.birthPlace,
            series: form.series,
            number: form.number,
            fmsCode: form.fmsCode,
            fmsUnit: form.fmsUnit,
            issueDate: form.issueDate,
        },
        regAddress: {
            city: form.regCity,
            region: form.regRegion,
            street: form.regStreet,
            house: form.regHouse,
            block: form.regBlock,
            apartment: form.regApartment,
        },
        utm: Sender.getUtms(),
        subs: Sender.getSubs(),
    };
};

var send = function(form) {
    var payload = getRequestPayload(form);
    window.phone = payload.phone;
    return Sender.send(getEndpointUrl(), payload);
};

export { send }
